import moment from 'moment';
import { firebase } from '@/firebase';

/**
 * Formatting dates using moment
 *
 * Accepts an optional format parameter
 *
 * @example {{ myDate | transformDate }}
 * @example {{ myDate | transformDate('YYYY-MM-DD') }}
 */
export const transformDate = (timestamp?: firebase.firestore.Timestamp | Date, format: string = 'MM-DD-YYYY'): string | undefined => {
  if (!timestamp) {
    return undefined;
  }

  return moment(timestamp instanceof firebase.firestore.Timestamp ? timestamp.toMillis() : (timestamp as Date)).format(format);
};

/**
 * Converts a Timestamp object into a Date object
 * @param date
 */
export const timestampToDate = (date?: firebase.firestore.Timestamp): Date | undefined => {
  if (!date) {
    return undefined;
  }

  return date.toDate();
};

/**
 * Converts a Date or Timestamp object based on brower's timezone to Date object UTC based
 * @param date
 */
export const convertLocalDateToUTC = (date?: Date | firebase.firestore.Timestamp): Date | undefined => {
  if (!date) {
    return undefined;
  }

  const tempDate = date instanceof Date ? date : timestampToDate(date as firebase.firestore.Timestamp)!;

  const newDate = Date.UTC(
    tempDate.getFullYear(),
    tempDate.getMonth(),
    tempDate.getDate(),
    tempDate.getHours(),
    tempDate.getMinutes(),
    tempDate.getSeconds(),
    tempDate.getMilliseconds(),
  );

  return new Date(newDate);
};

/**
 * Converts milliseconds or Timestamp object based on UTC date to a Date object based on current timezone
 * @param utcDate
 */
export const convertUTCToLocalDate = (utcDate?: Date | number | firebase.firestore.Timestamp): Date | undefined => {
  if (!utcDate) {
    return undefined;
  }

  return utcDate instanceof firebase.firestore.Timestamp ? timestampToDate(utcDate) : new Date(utcDate);
};

export default {
  transformDate,
  timestampToDate,
  convertLocalDateToUTC,
  convertUTCToLocalDate,
};
