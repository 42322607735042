/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'fundme.svg',
  logoalt: 'fundme-alt.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_fundme.scss',
  name: 'Fund.me',
  phone: '+31 (0) 612 345 678',
  email: 'info@fund.me',
  website: 'https://www.fund.me',
  glossary: false,
  langFileName: 'fundme.ts',
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  requireLegalBanner: true,
};
