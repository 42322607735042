import { CheckoutStepNames, Step } from '@/store/models/checkout';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { modules, requireContractAgreement, requireQuestionnaire } from '../../whitelabel.config';

export enum IdentCheckoutRouteNames {
  MAIN = 'checkoutIdentificationMain',
  IDIN = 'checkoutIdentificationIdin',
  WORLD = 'checkoutIdentificationWorld',
  BUSINESS = 'checkoutIdentificationBusiness',
}

export const IdentifcationPath = 'identification';
/*
* Steps in the checkout flow
* The order is the same as it is displayed in
* the identification and contract agreement are depended on the config in whitelabel.config
* */
export const steps: Step[] = [
  ...requireQuestionnaire
    ? [
      {
        name: CheckoutStepNames.Questionnaire,
        route: '/:lang?/checkout/questionnaire/',
        display: 'checkout.questionnaire.questionnaire',
      },
    ]
    : [],
  ...modules.identification
    ? [
    {
      name: CheckoutStepNames.Identification,
      route: `/:lang?/checkout/${IdentifcationPath}/`,
      display: 'settings.identification.identification',
    },
  ]
    : [],
  {
    name: CheckoutStepNames.Investment,
    route: '/:lang?/checkout/investment/',
    display: 'checkout.fund.selectAnAmount',
  },
  ...requireContractAgreement
    ? [
    {
      name: CheckoutStepNames.Terms,
      route: '/:lang?/checkout/legal/',
      display: 'checkout.legal.legalAgreement',
    },
  ]
    : [],
  {
    name: CheckoutStepNames.Payment,
    route: '/:lang?/checkout/payment/',
    display: 'common.subscription',
  },
];
